<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12" class="text-right hidden-print-only">
        <v-btn depressed color="primary" class="mt-1 mr-5" @click="printSaleVoucher">
          <v-icon>print</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" class="d-print-none">
        <v-container class="py-0">
          <v-row no-gutters>
            <v-col cols="2">
              <img :src="file" ref="cropper" style="height: 13vh;" />
            </v-col>
            <v-col cols="8" class="text-center pt-2">
              <v-row no-gutters>
                <v-col cols="12" class="display-2 first-heading-font">{{company.companyName}}</v-col>
                <v-col cols="12" class="headline font-weight-bold">
                  <span class="heading-font">TRAVELS & TOURS</span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <div class="vertical-divider"></div>
            </v-col>
            <v-col cols="12" class="text-center font-weight-bold title">"Official Receipt"</v-col>
            <v-col cols="6" class="text-left font-weight-bold">
              <v-row no-gutters>
                <v-col cols="1">To :</v-col>
                <v-col cols="11">
                  <span class="underlineVoucher">
                    {{voucher.toCustomer.customerName}}
                    <br />
                    {{voucher.toCustomer.phone}}
                  </span>
                </v-col>
                <v-col cols="1">For :</v-col>
                <v-col cols="11">
                  <span
                    class="underlineVoucher"
                    v-for="(forPerson,i) in voucher.forPerson"
                    v-bind:key="i"
                  >
                    {{forPerson.pass.passTypeName}} {{forPerson.name}}
                    <span
                      v-if="i+1 != voucher.forPerson.length"
                      class="title font-weight-bold"
                    >,</span>
                  </span>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" class="text-right font-weight-bold">
              <v-row no-gutters>
                <v-col cols="12">Date : {{voucher.date | voucherPrintDate }}</v-col>
                <v-col cols="12">{{voucher.voucherCode}}</v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="text-center pt-2">
              <font size="2">
                <official-voucher :company="company" :voucher="voucher" :file="file" />
              </font>
            </v-col>
            <v-col cols="4" class="caption" v-html="company.address"></v-col>
            <v-col cols="3" class="caption pl-4" v-html="'Ph: '+company.phoneNo"></v-col>
            <v-col cols="5" class="text-left caption">
              Email: {{company.email}}
              <br />
              {{company.website}}
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <!-- d-print-block d-none -->
      <v-col cols="6" class="caption d-print-block d-none rotateToLeft">
        <v-row no-gutters>
          <v-col cols="2">
            <img :src="file" ref="cropper" style="height: 13vh;" />
          </v-col>
          <v-col cols="8" class="text-center pt-2">
            <v-row no-gutters>
              <v-col cols="12" class="display-2 first-heading-font">{{company.companyName}}</v-col>
              <v-col cols="12" class="headline font-weight-bold">
                <span class="heading-font">TRAVELS & TOURS</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <div class="vertical-divider"></div>
          </v-col>
          <v-col cols="12" class="text-center font-weight-bold title">"Official Receipt"</v-col>
          <v-col cols="6" class="text-left font-weight-bold">
            <v-row no-gutters>
              <v-col cols="1">To :</v-col>
              <v-col cols="11">
                <span class="underlineVoucher">
                  {{voucher.toCustomer.customerName}}
                  <br />
                  {{voucher.toCustomer.phone}}
                </span>
              </v-col>
              <v-col cols="1">For :</v-col>
              <v-col cols="11">
                <span
                  class="underlineVoucher"
                  v-for="(forPerson,i) in voucher.forPerson"
                  v-bind:key="i"
                >
                  {{forPerson.pass.passTypeName}} {{forPerson.name}}
                  <span
                    v-if="i+1 != voucher.forPerson.length"
                    class="title font-weight-bold"
                  >,</span>
                </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="text-right font-weight-bold">
            <v-row no-gutters>
              <v-col cols="12">Date : {{voucher.date | voucherPrintDate }}</v-col>
              <v-col cols="12">{{voucher.voucherCode}}</v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="text-center pt-2">
            <font size="1">
              <official-voucher :company="company" :voucher="voucher" :file="file" />
            </font>
          </v-col>
          <v-col cols="4" class="caption" v-html="company.address"></v-col>
          <v-col cols="3" class="caption pl-4" v-html="'Ph: '+company.phoneNo"></v-col>
          <v-col cols="5" class="text-left caption">
            Email: {{company.email}}
            <br />
            {{company.website}}
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6" class="caption d-print-block d-none pt-1 hori-dotted-divider rotateToLeft">
        <v-row no-gutters>
          <v-col cols="2">
            <img :src="file" ref="cropper" style="height: 13vh;" />
          </v-col>
          <v-col cols="8" class="text-center pt-2">
            <v-row no-gutters>
              <v-col cols="12" class="display-2 first-heading-font">{{company.companyName}}</v-col>
              <v-col cols="12" class="headline font-weight-bold">
                <span class="heading-font">TRAVELS & TOURS</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <div class="vertical-divider"></div>
          </v-col>
          <v-col cols="12" class="text-center font-weight-bold title">"Official Receipt"</v-col>
          <v-col cols="6" class="text-left font-weight-bold">
            <v-row no-gutters>
              <v-col cols="1">To :</v-col>
              <v-col cols="11">
                <span class="underlineVoucher">
                  {{voucher.toCustomer.customerName}}
                  <br />
                  {{voucher.toCustomer.phone}}
                </span>
              </v-col>
              <v-col cols="1">For :</v-col>
              <v-col cols="11">
                <span
                  class="underlineVoucher"
                  v-for="(forPerson,i) in voucher.forPerson"
                  v-bind:key="i"
                >
                  {{forPerson.pass.passTypeName}} {{forPerson.name}}
                  <span
                    v-if="i+1 != voucher.forPerson.length"
                    class="title font-weight-bold"
                  >,</span>
                </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="text-right font-weight-bold">
            <v-row no-gutters>
              <v-col cols="12">Date : {{voucher.date | voucherPrintDate }}</v-col>
              <v-col cols="12">{{voucher.voucherCode}}</v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="text-center pt-2">
            <font size="1">
              <official-voucher :company="company" :voucher="voucher" :file="file" />
            </font>
          </v-col>
          <v-col cols="4" class="caption" v-html="company.address"></v-col>
          <v-col cols="3" class="caption pl-4" v-html="'Ph: '+company.phoneNo"></v-col>
          <v-col cols="5" class="text-left caption">
            Email: {{company.email}}
            <br />
            {{company.website}}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import companyInfoService from "../service/CompanyInfoService";
import voucherService from "../service/VoucherService";
import OfficialVoucher from "../components/VoucherOfficial";
import axios from "../config";
export default {
  data() {
    return {
      company: {},
      file: "",
      voucher: {
        toCustomer: {
          customerName: ""
        },
        way: [
          {
            travelDate: "",
            flight: {
              flightId: 0,
              flightName: ""
            }
          }
        ]
      }
    };
  },
  mounted: function() {
    let voucherId = this.$route.query.voucherId;
    if (voucherId == undefined || voucherId == null) {
      return;
    }
    this.voucherId = Number(voucherId);
    let code = this.$route.query.code;
    if (code == undefined || code == null) {
      return;
    }
    // this.code = Number(code);

    if (this.voucherId != 0) {
      this.getVoucherData(this.voucherId, code);
      this.getCompanyInfo();
    }
  },
  methods: {
    getVoucherData: function(voucherId, code) {
      voucherService.getVoucherDataForPrint(voucherId, code).then(response => {
        console.log(response);
        this.voucher = {};
        this.voucher = JSON.parse(JSON.stringify(response));
        var line = 0;
        for (var w = 0; w < this.voucher.way.length; w++) {
          if (
            this.voucher.way[w].roundTripId == null &&
            this.voucher.way[w].commission < 0
          ) {
            line++;
          } else if (
            this.voucher.way[w].roundTripId > 0 &&
            this.voucher.way[w].commission < 0
          ) {
            // changing commission from w to w + 1 and
            this.voucher.way[w + 1].pass = this.voucher.way[w].pass;
            this.voucher.way[w + 1].commission = this.voucher.way[w].commission;
            this.voucher.way[w + 1].mmkTotalCommission = this.voucher.way[
              w
            ].mmkTotalCommission;
            this.voucher.way[w + 1].usdTotalCommission = this.voucher.way[
              w
            ].usdTotalCommission;

            this.voucher.way[w].roundTripId = "";
            this.voucher.way[w].commission = 0;
            this.voucher.way[w].mmkTotalCommission = 0;
            this.voucher.way[w].usdTotalCommission = 0;
            line++;
            // for one loop over 2 step
            w = w + 1;
          } 
          else if (this.voucher.way[w].roundTripId > 0) {
            this.voucher.way[w + 1].roundTripId = this.voucher.way[
              w
            ].roundTripId;
            this.voucher.way[w].roundTripId = "";
            w = w + 1;
            line++;
          }
        }
        var placedRow = line + this.voucher.way.length;
        for (var i = 0; i < 6 - placedRow; i++) {
          this.voucher.way.push({
            wayId: 100 - i,
            travelDate: " ", //16 Dec
            flight: {
              flightId: 0,
              flightName: " "
            },
            className: " ",
            dept: " ", //11:10
            fromSector: {
              sectorId: 0,
              shortName: " "
            },
            toSector: {
              sectorId: 0,
              shortName: " "
            },
            pass: 0,
            passType: " ", //CHILD, INFANT
            fare: 0,
            baseFare: 0,
            commissionPercent: 0,
            commission: 1,
            usdTotalCommission: 0,
            mmkTotalCommission: 0,
            usdAmount: 0,
            mmkAmount: 0,
            status: "ACTIVE"
          });
        }
      });
    },
    printSaleVoucher: function() {
      window.print();
    },
    // account
    getCompanyInfo: function() {
      companyInfoService.getCompanyInfo().then(response => {
        this.company = Object.assign({}, response);
        this.file = `${axios.defaults.baseURL}/companyLogo/${response.logo}`;
      });
    }
  },
  components: {
    "official-voucher": OfficialVoucher
  }
};
</script>
<style scoped>
@font-face {
  font-family: "Handage";
  src: url("/font/HANDA__.woff") format("woff");
  src: url("/font/HANDA.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Haettenschweiler";
  src: url("/font/Haettenschweiler.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.heading-font {
  font-family: "Handage";
  letter-spacing: 0.3em;
}
.first-heading-font {
  font-family: "Haettenschweiler" !important;
  font-weight: 300;
  letter-spacing: 0.1em !important;
}
.vertical-divider {
  border-top: 2px solid black;
}
.rotateToLeft {
  margin: 100px 0 0 0px; /*Changed*/
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
}
</style>
